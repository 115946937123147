import React, { useEffect, useState } from 'react'
import { db, functions } from '../helpers/firebase';
import { doc, getDocs, collection, setDoc, serverTimestamp } from "firebase/firestore";
import { ArrowBackIos, Close, Done, Height, Search, Sync, SyncAlt } from '@mui/icons-material';
import Select from 'react-select';
import Swal from 'sweetalert2';
import Footer from '../components/Footer';
import { useTopbar } from '../helpers/TopbarContext';
import { useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

export default function RelacionarTiendas() {
    const [stores, setStores] = useState([]);
    const [selectedStoreFrom, setSelectedStoreFrom] = useState(null);
    const [selectedStoreTo, setSelectedStoreTo] = useState(null);
    const [syncWithImageClicked, setSyncWithImageClicked] = useState(false);

    const { currentUser } = useAuth();

    const [toListings, setToListings] = useState([]);
    const [toListingsOptions, setToListingsOptions] = useState([]);

    const [synced, setSynced] = useState(false);
    const [relations, setRelations] = useState({});

    useEffect(() => {
        document.title = 'Relacionar tiendas | Kompa';

        getUserStores();
    }, [currentUser]);

    const handleSync = async () => {
        // Initial validation
        if (!selectedStoreFrom || !selectedStoreTo) {
            Swal.fire({
                title: 'Error',
                text: 'Debes seleccionar ambas tiendas',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#3B82F6',
            });
            return;
        }
        setSyncWithImageClicked(true);

        try {
            // Get from listings from firestore first
            const fromListingsRef = collection(db, 'users', currentUser.uid, 'stores', selectedStoreFrom.id, 'listings');
            console.log("Fetching from listings path:", fromListingsRef.path);

            const fromListingsSnapshot = await getDocs(fromListingsRef);
            const fromListingsList = fromListingsSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));

            // Get to listings from firestore
            const toListingsRef = collection(db, 'users', currentUser.uid, 'stores', selectedStoreTo.id, 'listings');
            console.log("Fetching to listings path:", toListingsRef.path);

            const toListingsSnapshot = await getDocs(toListingsRef);
            const toListingsList = toListingsSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));

            // Set to listings for the dropdown options
            setToListings(toListingsList);

            // Convert to listings to options format for the dropdown
            const toListingsOptionsFormatted = toListingsList.map(listing => ({
                value: listing.id,
                label: listing.listing_title || listing.item_name,
                id: listing.id,
                ...listing
            }));
            setToListingsOptions(toListingsOptionsFormatted);

            // Initialize relations with all fromListings
            let newRelations = fromListingsList.reduce((acc, listing) => {
                acc[listing.id] = { from: listing, to: null };
                return acc;
            }, {});

            // Call your custom API
            console.log("Starting AI matching attempt...");

            const requestData = {
                user_id: currentUser.uid,
                source_store: selectedStoreFrom.id,
                target_store: selectedStoreTo.id,
                top_k: 5
            };

            console.log("Preparing API request with data:", requestData);

            const apiUrl = 'https://boar-balanced-actually.ngrok-free.app/api/find-matches';
            console.log("API endpoint URL:", apiUrl);

            const headers = {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            };
            console.log("Request headers:", headers);

            console.log("Sending API request...");
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(requestData),
            });

            console.log("Received API response with status:", response.status);
            console.log("Response headers:", Object.fromEntries(response.headers.entries()));

            if (!response.ok) {
                const errorText = await response.text();
                console.error("Error response body:", errorText);
                throw new Error(`API responded with status: ${response.status}. Body: ${errorText}`);
            }

            const resultRelations = await response.json();
            console.log("API Response data:", resultRelations);

            // Check if we have matches in the expected format
            if (!resultRelations.matches) {
                console.error("Unexpected response format - missing matches array");
                throw new Error("Invalid response format from API");
            }

            console.log("AI matching succeeded. Similar listings found:", resultRelations.matches.length);

            // Update existing relations with AI matches if successful
            // Update existing relations with AI matches if successful
            let matchesApplied = 0;
            for (const match of resultRelations.matches) {
                console.log("Processing match:", match);

                // Make sure we have the source doc_id and matches array
                if (!match.doc_id || !match.matches || !Array.isArray(match.matches)) {
                    console.warn('Skipping invalid match:', match);
                    continue;
                }

                // Find the relation for this source product
                if (!newRelations[match.doc_id]) {
                    console.warn('No relation found for source product:', match.doc_id);
                    continue;
                }

                // Get the first match (best match)
                if (match.matches.length > 0) {
                    const targetMatch = match.matches[0];
                    const toListing = toListingsList.find(l => l.id === targetMatch.target_id);

                    if (toListing) {
                        newRelations[match.doc_id].to = toListing;
                        matchesApplied++;
                    } else {
                        console.warn('Target listing not found:', targetMatch);
                    }
                }
            }

            // Set the relations state with the updated relations
            setRelations(newRelations);

            console.log("AI matches applied:", {
                totalMatches: matchesApplied,
                totalRelations: Object.keys(newRelations).length,
                sampleMatchedRelation: Object.values(newRelations).find(r => r.to !== null)
            });

        } catch (fetchError) {
            console.error("Fetch error details:", {
                name: fetchError.name,
                message: fetchError.message,
                stack: fetchError.stack,
                cause: fetchError.cause
            });

            Swal.fire({
                title: 'Error',
                text: `Error al sincronizar: ${fetchError.message}`,
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#3B82F6',
            });

        } finally {
            setSynced(true);
            setSyncWithImageClicked(false);
        }
    };

    const getUserStores = async () => {
        if (!currentUser) return;
        const storesRef = collection(db, 'users', currentUser.uid, 'stores');
        console.log('storesRef: ', storesRef.path);
        const storesSnapshot = await getDocs(storesRef);
        console.log('storesSnapshot: ', storesSnapshot.docs.length);
        const storesList = storesSnapshot.docs.map(doc => {
            const store = doc.data();
            return {
                id: doc.id,
                value: store.custom_name,
                label: <div
                    className='flex flex-row items-center'
                >
                    <img
                        // si empieza con ml usamos el logo de mercado libre
                        src={
                            doc.id.startsWith('ml') ?
                                `https://http2.mlstatic.com/frontend-assets/ui-navigation/5.18.5/mercadolibre/logo__large_plus.png` :
                                doc.id.startsWith('az') &&
                                `https://upload.wikimedia.org/wikipedia/commons/thumb/6/62/Amazon.com-Logo.svg/1200px-Amazon.com-Logo.svg.png`
                        }
                        className='w-16 mr-4 object-contain'
                        alt="" />
                    {store.custom_name}
                </div>
            }
        }
        );
        setStores(storesList);
    };

    // Función para manejar el cambio en el primer selector
    const handleChangeStoreFrom = selectedOption => {
        setSelectedStoreFrom(selectedOption);
    };

    // Función para manejar el cambio en el segundo selector
    const handleChangeStoreTo = selectedOption => {
        setSelectedStoreTo(selectedOption);
    };

    // Estado para manejar las selecciones de productos
    const [selectedProducts, setSelectedProducts] = useState({});

    // Función para manejar el cambio en los selectores de productos
    const handleProductChange = (productId, selectedOption) => {
        setSelectedProducts(prevSelectedProducts => ({
            ...prevSelectedProducts,
            [productId]: selectedOption
        }));

        // update relations
        setRelations(prevRelations => ({
            ...prevRelations,
            [productId]: {
                ...prevRelations[productId],
                to: toListings.find(l => l.id === selectedOption.id)
            }
        }));
    };

    // Función para eliminar una relación
    const removeRelation = (productId) => {
        setSelectedProducts(prevSelectedProducts => ({
            ...prevSelectedProducts,
            [productId]: null
        }));

        // update relations
        setRelations(prevRelations => ({
            ...prevRelations,
            [productId]: {
                ...prevRelations[productId],
                to: null
            }
        }));
    }

    const clearStates = () => {
        setSelectedStoreFrom(null);
        setSelectedStoreTo(null);
        setSynced(false);
        setRelations({});
        setSelectedProducts({});
    }

    // Función para ordenar y mostrar los productos
    const renderProducts = () => {
        // Lista de productos con relación
        const productsWithRelation = [];
        // Lista de productos sin relación
        const productsWithoutRelation = [];

        // Separar los productos en dos listas
        Object.keys(relations).forEach(key => {
            const relation = relations[key];
            if (relation.to) {
                productsWithRelation.push(
                    renderProduct(relation) // Función para renderizar un producto individual
                );
            } else {
                productsWithoutRelation.push(
                    renderProduct(relation) // Función para renderizar un producto individual
                );
            }
        });

        // Renderizar primero los productos con relación y luego los sin relación
        return [...productsWithRelation, ...productsWithoutRelation];
    };

    const renderProduct = (relation) => {
        return (
            <div
                key={relation.from.id}
                className='flex flex-col items-center justify-center w-96 mt-4'
            >
                <div
                    key={relation.from.id}
                    className='flex flex-row items-center justify-center'
                >

                    <div
                        className='flex flex-row items-center mr-4 bg-white shadow-md px-4 w-72 py-1 rounded-xl'
                    >
                        <div
                            className='w-1/4 flex flex-row items-center justify-center cursor-pointer'
                            onClick={() => window.open(relation.from.listing_permalink || `https://www.amazon.com.mx/dp/${relation.from.asin1}`, '_blank')}
                        >
                            <img
                                src={relation.from.thumbnail || 'https://via.placeholder.com/150'}
                                className='h-16 w-16 object-contain'
                                alt="" />
                        </div>

                        <div className='flex flex-col items-center justify-center w-3/4 pl-4 mr-4'>
                            <p className='text-gray-600 text-sm font-semibold truncate w-full'>
                                {relation.from.listing_title || (relation.from.item_name && relation.from.item_name.replace(/�/g, "'"))}
                            </p>
                            <p className='text-gray-500 text-sm truncate w-full'>
                                $ {relation.from.price || relation.from.listing_price}.00
                            </p>
                            <p className='text-gray-400 text-sm truncate w-full'>
                                {relation.from.id}
                            </p>
                        </div>
                    </div>

                    <Height
                        className='text-gray-600 transform rotate-90'
                        fontSize='large'
                    />

                    {relation.to ?
                        <div
                            className='flex flex-row items-center ml-4 bg-white shadow-md px-4 w-72 py-1 rounded-xl relative'
                        >
                            <div
                                className='w-1/4 flex flex-row items-center justify-center cursor-pointer'
                                onClick={() => window.open(relation.to.listing_permalink || `https://www.amazon.com.mx/dp/${relation.to.asin1}`, '_blank')}
                            >
                                <img
                                    src={relation.to.thumbnail || 'https://via.placeholder.com/150'}
                                    className='h-16 w-16 object-contain'
                                    alt="" />
                            </div>

                            <div className='flex flex-col items-center justify-center w-3/4 pl-4 mr-4'>
                                <p className='text-gray-600 text-sm font-semibold truncate w-full'>
                                    {relation.to.listing_title || (relation.to.item_name && relation.to.item_name.replace(/�/g, "'"))}
                                </p>
                                <p className='text-gray-500 text-sm truncate w-full'>
                                    $ {relation.to.price || relation.to.listing_price}.00
                                </p>
                                <p className='text-gray-400 text-sm truncate w-full'>
                                    {relation.to.id}
                                </p>
                            </div>

                            <Close
                                onClick={() => removeRelation(relation.from.id)}
                                className='text-gray-400 hover:text-gray-600 cursor-pointer absolute transition duration-100 ease-in-out right-1 top-1'
                                fontSize='small'
                            />

                        </div>
                        :
                        <div
                            className='flex flex-row items-center ml-4 px-4 w-72 py-1 rounded-xl border-dashed border-2 border-gray-300'
                        >
                            <Search
                                className='text-gray-400'
                            />

                            <Select
                                options={toListingsOptions}
                                className='w-72 ml-4'
                                isClearable
                                placeholder='Buscar un producto'
                                value={selectedProducts[relation.from.id]}
                                onChange={(selectedOption) => handleProductChange(relation.from.id, selectedOption)}
                                // set background color to transparent
                                styles={{
                                    control: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: 'transparent',
                                        border: 'none',
                                        boxShadow: 'none',
                                        cursor: 'pointer',
                                        '&:hover': {
                                            border: 'none',
                                            boxShadow: 'none',
                                        }
                                    }),
                                    menu: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: 'white',
                                    }),
                                }}

                            />

                            <p
                                className='h-16'
                            />
                        </div>
                    }
                </div>
            </div >
        )
    }

    const [uploadingRelations, setUploadingRelations] = useState(false);
    const {
        setRelation,
        relations: relationsFromTopbar,
        setRelations: setRelationsFromTopbar
    } = useTopbar();

    const navigate = useNavigate();

    const uploadRelations = async () => {
        if (!currentUser) return;

        setUploadingRelations(true);

        try {
            if (!selectedStoreFrom || !selectedStoreTo) {
                throw new Error('Debes seleccionar ambas tiendas');
            }

            // Pedir al usuario que ingrese un nombre para la relación
            const { value: relationName } = await Swal.fire({
                title: 'Asignar un Nombre a esta Relación',
                input: 'text',
                // inputLabel: 'Nombre de la relación',
                inputPlaceholder: 'Escribe el nombre para esta relación',
                confirmButtonText: 'Guardar Relación',
                confirmButtonColor: '#3B82F6',
                showCancelButton: true,
                cancelButtonColor: '#d33',
                inputValidator: (value) => {
                    if (!value) {
                        return '¡Necesitas escribir un nombre!';
                    }
                }
            });

            if (relationName) {
                // Identificador único para la relación de tiendas, asegurándote de que el orden sea consistente (por ejemplo, alfabético)
                const storeRelationId = [selectedStoreFrom.id, selectedStoreTo.id].sort().join('_');

                const relationRef = doc(db, 'users', currentUser.uid, 'relatedStores', storeRelationId);

                // Guardar el nombre de la relación y las tiendas relacionadas
                await setDoc(relationRef, {
                    name: relationName,
                    storeFrom: selectedStoreFrom.id,
                    storeTo: selectedStoreTo.id,
                    createdAt: serverTimestamp()
                });

                // Guardar las relaciones específicas de productos en la subcolección 'relations'
                for (const key of Object.keys(relations)) {
                    const relation = relations[key];
                    if (relation.to) {
                        const productRelationRef = doc(db, 'users', currentUser.uid, 'relatedStores', storeRelationId, 'relations', relation.from.id);
                        await setDoc(productRelationRef, {
                            ...relation.from,
                            from: relation.from,
                            to: relation.to
                        });
                    }
                }

                Swal.fire({
                    title: '¡Listo!',
                    text: 'Se han sincronizado los productos con el nombre de relación "' + relationName + '"',
                    icon: 'success',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#3B82F6',
                });

                clearStates();
                setRelation(
                    {
                        id: storeRelationId,
                        value: storeRelationId,
                        label: relationName,
                        data: {
                            name: relationName,
                            storeFrom: selectedStoreFrom.id,
                            storeTo: selectedStoreTo.id,
                            createdAt: serverTimestamp()
                        }
                    }
                )
                // if last relation is + Crear nueva relacion
                if (relationsFromTopbar.length > 0 &&
                    relationsFromTopbar[relationsFromTopbar.length - 1].value === 'create_new') {
                    setRelationsFromTopbar(prevRelations => {
                        return [
                            ...prevRelations.slice(0, prevRelations.length - 1),
                            {
                                id: storeRelationId,
                                value: storeRelationId,
                                label: relationName,
                                data: {
                                    name: relationName,
                                    storeFrom: selectedStoreFrom.id,
                                    storeTo: selectedStoreTo.id,
                                    createdAt: serverTimestamp()
                                }
                            },
                            {
                                id: 'create_new',
                                value: 'create_new',
                                label: <span className='text-kompamxblue font-semibold'>
                                    + Crear nueva relación
                                </span>
                            }
                        ]
                    });
                }
                else {
                    setRelationsFromTopbar(prevRelations => {
                        return [
                            ...prevRelations,
                            {
                                id: storeRelationId,
                                value: storeRelationId,
                                label: relationName,
                                data: {
                                    name: relationName,
                                    storeFrom: selectedStoreFrom.id,
                                    storeTo: selectedStoreTo.id,
                                    createdAt: serverTimestamp()
                                }
                            }
                        ]
                    });
                }
                navigate('/comparador/graficos');
            } else {
                throw new Error('Operación cancelada por el usuario');
            }
        } catch (error) {
            if (error.message === 'Operación cancelada por el usuario') return;

            console.error("Error uploading relations: ", error);
            Swal.fire({
                title: 'Error',
                text: `Ocurrió un error al sincronizar los productos: ${error.message}`,
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#3B82F6',
            });
        } finally {
            setUploadingRelations(false);
        }
    }

    return (
        <div style={{ height: 'calc(100vh - 4rem)', overflowY: 'auto' }}>

            <div id='content-to-pdf' className='relative flex flex-col justify-start items-center bg-gray-100 py-10 px-5' style={{ height: 'calc(100vh - 4rem)', overflowY: 'auto' }}>
                {synced &&
                    <div
                        className='absolute left-0 top-0 mt-8 ml-8 cursor-pointer flex flex-row items-center hover:text-blue-600 text-blue-500 transition duration-100 ease-in-out text-xl'
                        onClick={() => clearStates()}
                    >
                        <ArrowBackIos />
                        <p>
                            Volver
                        </p>
                    </div>
                }

                {synced &&
                    <h1
                        className='text-4xl font-medium text-gray-800 border-b-2 border-gray-300 pb-4 px-6'
                    >
                        Relacionar Publicaciones
                    </h1>
                }

                {  // Si se ha sincronizado, mostramos el resultado
                    synced ?
                        renderProducts()
                        :
                        <div
                            className='flex flex-col items-center justify-center mt-4 h-full'
                        >
                            <h1
                                className='text-4xl font-medium text-gray-800 border-b-2 border-gray-300 pb-4 px-6'
                            >
                                Relacionar Dos Tiendas
                            </h1>
                            <div
                                className='flex flex-col md:flex-row items-center md:items-end justify-center'
                            >
                                <div className='flex flex-col items-center justify-center'>
                                    <p
                                        className='text-xl text-gray-600'
                                    >
                                        Desde tienda
                                    </p>

                                    <Select
                                        options={stores}
                                        className='w-96 mt-2'
                                        isClearable
                                        placeholder='Selecciona una tienda'
                                        value={selectedStoreFrom}
                                        onChange={handleChangeStoreFrom}
                                    />
                                </div>

                                <SyncAlt className='text-gray-600 text-4xl mx-8 my-4 md:my-1 rotate-90 md:rotate-0' fontSize='large' />

                                <div className='flex flex-col items-center justify-center'>
                                    <p
                                        className='text-xl md:mt-4 text-gray-600'
                                    >
                                        Hacia tienda
                                    </p>
                                    <Select
                                        options={stores}
                                        className='w-96 mt-2'
                                        isClearable
                                        placeholder='Selecciona una tienda'
                                        value={selectedStoreTo}
                                        onChange={handleChangeStoreTo}
                                    />
                                </div>
                            </div>

                            <button
                                className={
                                    syncWithImageClicked ?
                                        'py-2 px-4 rounded transition font-medium duration-100 ease-in-out shadow-lg bg-gray-500 cursor-not-allowed text-white mt-8' :
                                        'bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-100 ease-in-out hover:shadow-lg shadow-md mt-8'
                                }
                                onClick={() => handleSync(true)}
                                disabled={syncWithImageClicked}
                            >
                                <div
                                    className='flex flex-row items-center justify-center'
                                >
                                    {
                                        syncWithImageClicked ?
                                            'Sincronizando...' :
                                            'Encontrar productos similares'
                                    }

                                    <Sync
                                        style={{
                                            marginLeft: '8px',
                                            animation: syncWithImageClicked ? 'spin 1s linear infinite' : 'none'
                                        }}
                                        className='text-white'
                                    />
                                </div>
                            </button>
                        </div>
                }

                {synced &&
                    <button
                        className={
                            uploadingRelations ?
                                'py-2 px-4 rounded mt-4 w-96 transition font-medium duration-100 ease-in-out shadow-lg bg-gray-500 cursor-not-allowed text-white' :
                                'bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded mt-4 w-96 transition duration-100 ease-in-out hover:shadow-lg shadow-md'
                        }
                        onClick={uploadRelations}
                        disabled={uploadingRelations}
                    >
                        <div
                            className='flex flex-row items-center justify-center'
                        >
                            Confirmar sincronización
                            <Done
                                className='ml-4'
                            />
                        </div>
                    </button>
                }
            </div>
            <Footer />
        </div>
    )
}
