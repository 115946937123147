import { useState, useEffect } from 'react';
import { auth as firebaseAuth, db } from '../helpers/firebase';
import { doc, getDoc, serverTimestamp, setDoc } from 'firebase/firestore';
import { signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import { useTest } from '../helpers/TestContext';
import supabaseClient from '../helpers/supabase';

function useAuth() {
  const { testMode } = useTest();
  const [currentUser, setCurrentUser] = useState(null);
  const [loadingUser, setLoadingUser] = useState(true);
  const TEST_USER_ID = 'ZRi0TNwjK0ayv4CGFmeKhuSkbQ63';

  const createSupabaseAccount = async (email, password) => {
    try {
      const { data, error } = await supabaseClient.auth.signUp({
        email,
        password,
        options: {
          emailRedirectTo: window.location.origin,
        }
      });

      if (error) throw error;
      return data;
    } catch (error) {
      console.error('Error creating Supabase account:', error);
      throw error;
    }
  };

  const signInWithSupabase = async (email, password) => {
    try {
      const { data, error } = await supabaseClient.auth.signInWithPassword({
        email,
        password,
      });

      if (error) throw error;
      return data;
    } catch (error) {
      console.error('Error signing in with Supabase:', error);
      throw error;
    }
  };

  const signInWithFirebase = async (email, password) => {
    try {
      const result = await signInWithEmailAndPassword(firebaseAuth, email, password);
      return result;
    } catch (error) {
      console.error('Error signing in with Firebase:', error);
      throw error;
    }
  };

  const fetchUserData = async (user, isSupabaseUser = false) => {
    setLoadingUser(true);

    try {
      if (user) {
        if (testMode) {
          const userRef = doc(db, 'users', TEST_USER_ID);
          const userSnap = await getDoc(userRef);
          if (userSnap.exists()) {
            setCurrentUser({ ...userSnap.data(), uid: userSnap.id, user: { ...userSnap.data(), uid: userSnap.id } });
          }
          return;
        }

        // Handle Supabase user data
        if (isSupabaseUser) {
          const userDataToSave = {
            uid: user.id,
            email: user.email,
            provider: 'supabase',
            lastLogin: serverTimestamp(),
          };

          // Store Supabase user data in Firebase for backwards compatibility
          const userRef = doc(db, 'users', user.id);
          await setDoc(userRef, userDataToSave, { merge: true });

          setCurrentUser({
            ...userDataToSave,
            user
          });
          return;
        }

        // Handle Firebase user data
        const userRef = doc(db, 'users', user.uid);
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
          const userDataToSave = {
            uid: user.uid,
            email: user.email,
            displayName: user.displayName,
            photoURL: user.photoURL,
            provider: 'firebase',
            lastLogin: serverTimestamp(),
          };
          await setDoc(userRef, userDataToSave, { merge: true });
          setCurrentUser({ ...userSnap.data(), ...userDataToSave, user });
        } else {
          const userDataToSave = {
            uid: user.uid,
            email: user.email,
            displayName: user.displayName,
            photoURL: user.photoURL,
            provider: 'firebase',
            createdAt: serverTimestamp(),
          };
          await setDoc(userRef, userDataToSave, { merge: true });
          setCurrentUser({ ...userDataToSave, user });
        }
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      throw error;
    } finally {
      setLoadingUser(false);
    }
  };

  const handleAuthentication = async (email, password) => {
    try {
      // 1. Try Supabase authentication first
      const supabaseUser = await signInWithSupabase(email, password);
      if (supabaseUser) {
        await fetchUserData(supabaseUser.user, true);
        return;
      }
    } catch (supabaseError) {
      console.log('Supabase auth failed, trying Firebase...');

      try {
        // 2. Try Firebase authentication
        const firebaseResult = await signInWithFirebase(email, password);

        if (firebaseResult.user) {
          try {
            // 3. Create Supabase account with same credentials
            await createSupabaseAccount(email, password);

            // 4. Sign in to Supabase with created account
            const supabaseUser = await signInWithSupabase(email, password);
            await fetchUserData(supabaseUser.user, true);
          } catch (createSupabaseError) {
            // If Supabase account creation fails, still allow Firebase login
            console.warn('Failed to create Supabase account:', createSupabaseError);
            await fetchUserData(firebaseResult.user, false);
          }
        }
      } catch (firebaseError) {
        // 6. If Firebase auth fails, throw error
        console.error('Firebase authentication failed:', firebaseError);
        throw firebaseError;
      }
    }
  };

  useEffect(() => {
    let unsubscribe = () => { };
    let supabaseUnsubscribe = null;

    const setupAuth = async () => {
      try {
        if (testMode) {
          await fetchUserData({ uid: TEST_USER_ID });
        } else {
          // Listen for auth state changes in Supabase
          const { data: { subscription } } = supabaseClient.auth.onAuthStateChange(
            async (event, session) => {
              if (session?.user) {
                await fetchUserData(session.user, true);
              }
            }
          );
          supabaseUnsubscribe = subscription;

          // Listen for Firebase auth state changes
          unsubscribe = onAuthStateChanged(firebaseAuth, async (user) => {
            if (!user) {
              if (!currentUser?.provider || currentUser.provider === 'firebase') {
                setCurrentUser(null);
              }
              return;
            }
            if (!testMode && (!currentUser?.provider || currentUser.provider === 'firebase')) {
              await fetchUserData(user, false);
            }
          });
        }
      } catch (error) {
        console.error('Error in useAuth:', error);
      } finally {
        setLoadingUser(false);
      }
    };

    setupAuth();

    return () => {
      unsubscribe();
      if (supabaseUnsubscribe) supabaseUnsubscribe.unsubscribe();
    };
  }, [testMode]);

  return { currentUser, setCurrentUser, loadingUser, handleAuthentication };
}

export default useAuth;