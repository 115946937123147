import React from 'react'
import { Link } from 'react-router-dom'
import { Logo } from '../../components/onboarding/Logo'
import { DisenoFino } from '../../components/onboarding/DisenoFino'
import { Boton } from '../../components/onboarding/Boton'

export default function Registro() {
  const handleWhatsAppRedirect = () => {
    window.open('https://wa.me/5215531114274', '_blank')
  }

  return (
    <DisenoFino>
      <div className="flex">
        <Link to="/" aria-label="Inicio">
          <Logo className="h-10 w-auto" />
        </Link>
      </div>

      <div className="mt-20 text-center">
        <h2 className="text-lg font-semibold text-gray-900">
          Crear una cuenta nueva
        </h2>

        <p className="mt-4 text-sm text-gray-700">
          Para crear una cuenta nueva, por favor contáctanos a través de WhatsApp.
          Nuestro equipo te ayudará con el proceso de registro.
        </p>

        <div className="mt-8">
          <Boton
            type="button"
            variante="solido"
            color="azul"
            className="w-full sm:w-auto"
            onClick={handleWhatsAppRedirect}
          >
            <span className="flex items-center justify-center">
              Contactar por WhatsApp
              <span aria-hidden="true" className="ml-2">&rarr;</span>
            </span>
          </Boton>
        </div>

        <p className="mt-6 text-sm text-gray-700">
          ¿Ya tienes una cuenta?{' '}
          <Link
            to="/iniciar-sesion"
            className="font-medium text-blue-600 hover:underline"
          >
            Inicia sesión
          </Link>
        </p>
      </div>
    </DisenoFino>
  )
}