import { useState, useEffect } from 'react'
import { Boton } from '../../components/onboarding/Boton'
import { CampoTexto } from '../../components/onboarding/Campos'
import { Logo } from '../../components/onboarding/Logo'
import { DisenoFino } from '../../components/onboarding/DisenoFino'
import { Link, useNavigate } from 'react-router-dom'
import { auth } from '../../helpers/firebase'
import { sendPasswordResetEmail } from 'firebase/auth'
import useAuth from '../../hooks/useAuth'
import { useTest } from '../../helpers/TestContext'

export const metadata = {
  title: 'Iniciar Sesión',
}

export default function IniciarSesion() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [errorColor, setErrorColor] = useState('red');
  const navigate = useNavigate();
  const { testMode, setTestMode } = useTest();
  const { handleAuthentication } = useAuth();

  const handleSignIn = async (e) => {
    e.preventDefault();
    if (loading) return;

    // Check for test mode activation
    if (email.toLowerCase() === 'test@test' && password.toLowerCase() === 'test') {
      setTestMode(!testMode);
      setError(`Modo de prueba ${!testMode ? 'activado' : 'desactivado'}`);
      setErrorColor('blue');
      return;
    }

    setLoading(true);
    try {
      await handleAuthentication(email, password);
      navigate('/tablero');
    } catch (error) {
      console.error(error.code, error.message);
      setErrorColor('red');

      switch (error.code) {
        case 'auth/wrong-password':
          setError('La contraseña es incorrecta');
          break;
        case 'auth/user-not-found':
          setError('No hay ningún usuario registrado con este correo electrónico');
          break;
        case 'auth/invalid-email':
          setError('El correo electrónico no es válido');
          break;
        case 'auth/invalid-login-credentials':
          setError('El correo electrónico o la contraseña no son válidos');
          break;
        default:
          setError('Error al iniciar sesión');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPassword = async () => {
    if (!email) {
      setError('Por favor, introduce tu correo electrónico antes de restablecer la contraseña.');
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      setError('Se ha enviado un correo electrónico para restablecer tu contraseña. Revisa también tu carpeta de spam.');
      setErrorColor('blue');
    } catch (error) {
      setErrorColor('red');
      if (error.code === 'auth/invalid-email') {
        setError('El correo electrónico no es válido');
      } else if (error.code === 'auth/user-not-found') {
        setError('No existe ninguna cuenta con este correo electrónico');
      } else {
        setError('Error al enviar el correo de restablecimiento');
      }
    }
  };

  return (
    <DisenoFino>
      <div className="flex">
        <Link to="/" aria-label="Inicio">
          <Logo className="h-10 w-auto" />
        </Link>
      </div>
      <h2 className="mt-20 text-lg font-semibold text-gray-900">
        Inicia sesión en tu cuenta
      </h2>
      <p className="mt-2 text-sm text-gray-700">
        ¿No tienes una cuenta?{' '}
        <Link
          to="/registro"
          className="font-medium text-blue-600 hover:underline"
        >
          Regístrate
        </Link>{' '}
        para una prueba gratuita.
      </p>
      <form onSubmit={handleSignIn} className="mt-10 grid grid-cols-1 gap-y-8">
        <CampoTexto
          etiqueta="Correo electrónico"
          nombre="email"
          tipo="email"
          autoComplete="email"
          required
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
        <CampoTexto
          etiqueta="Contraseña"
          nombre="password"
          tipo="password"
          autoComplete="current-password"
          required
          onChange={(e) => setPassword(e.target.value)}
          value={password}
        />
        <div>
          <Boton
            type="submit"
            variante="solido"
            color="azul"
            className="w-full"
            disabled={loading}
          >
            <span>
              {loading ? 'Iniciando sesión...' : 'Iniciar sesión'} {!loading && <span aria-hidden="true">&rarr;</span>}
            </span>
          </Boton>
        </div>
        <div className="text-center">
          <button
            type="button"
            className="font-medium text-blue-600 hover:underline"
            onClick={handleForgotPassword}
          >
            ¿Olvidaste tu contraseña?
          </button>
        </div>
        {error && (
          <div className="text-center">
            <p className={`text-sm text-${errorColor}-600`}>{error}</p>
          </div>
        )}
      </form>
    </DisenoFino>
  )
}